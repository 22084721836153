import React from 'react'
import images from '../../constants/images';
import './Header.css'

const Header = () => {
    // const headerStyle = {
    //     backgroundColor: "#DEF9FF",
    // };

    return (
        <div style={{ marginTop: '2%', marginBottom: '2%' }}>
            <img className='app__header-logo-image' src={images.application_logo_new} alt='image' style={{width:'20%'}}/>
            {/* <div>
            <img className='app__header-logo-image' src={images.urduhaimeraname} alt='image' />
            </div> */}
            <div className='app__header-header-main' >
                <div className='app__header-header-secondary'>
                    <div className='app__header-header-home'>
                        <a href="/">HOME</a>
                    </div>
                    <div className='app__header-header-contact'>
                        <a href="/instructions" >HOW TO</a>
                    </div>
                    <div className='app__header-header-contact'>
                        <a href="/download" >DOWNLOAD</a>
                    </div>
                    <div className='app__header-header-team'>
                        <a href="/team">TEAM</a>
                    </div>
                    <div className='app__header-header-contributions'>
                        <a href="/contributions">CONTRIBUTIONS</a>
                    </div>
                    <div className='app__header-header-contact'>
                        <a href="/contact">CONTACT</a>
                    </div>
                    <div className='app__header-header-about'>
                        <a href="/about">ABOUT</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header