// import React from 'react';
// import { Home } from './container';
// import { Instructions } from './container';

// import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <Home />
//       <Instructions />
//     </div>
//   );
// }

// export default App;


import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Home } from './container';
import { Instructions } from './container';
import { About } from './container';
import { Team } from './container';
import { Contributions } from './container';
import { Contact } from './container';
import { Download } from './container';
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/instructions" element={<Instructions />} />
          <Route path="/about" element={<About />} />
          <Route path="/team" element={<Team />} />
          <Route path="/contributions" element={<Contributions />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/download" element={<Download />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;