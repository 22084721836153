import React, { useRef, useState } from 'react';
import images from '../../constants/images';
import './Body.css';

const Body = () => {

    // const setZoomTo80 = () => {

    //     const viewportWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    //     console.log(viewportWidth)
    //     const breakpoint = 1536;
    //     if (viewportWidth <= breakpoint) {
    //         const zoomLevel = 80;
    //         document.body.style.zoom = `${zoomLevel}%`;
    //     } else {
    //         document.body.style.zoom = '100%';
    //     }
    //     // document.body.style.zoom = '80%';
    //   };
    // setZoomTo80();

                            //     <div style={{"paddingTop": "70px", "paddingBottom": "64px"}}>
                            //     <img
                            //         src={images.qrcode}
                            //         alt='image'
                            //         />   
                            // </div>
    
    return (
        
    <div className="body-container">
            <div className="centered-box">
                <div className="centered-main-box">
                    <div className='app__body-head'>
                        <div className='app__body-head-main'>
                            <h2>Download Mobile Application</h2>
                            <p style={{ marginTop: '-20px', marginBottom: '-2px'}}>Scan the QR code below to download.</p>
                        </div>
                        <div className='app__body-head-secondary' style={{ marginTop: '10px' }}>
                            <img src={images.ncl} style={{"marginRight": "10px"}} alt='image' />
                            <img src={images.neduet} style={{"marginRight": "10px"}} alt='image' />
                            <img src={images.atup} alt='image' />
                        </div>
                    </div>

                    <div className='app__body-cards' style={{marginLeft: '10px'}}>
                        <div className='app__body-card'>              
                            <div style={{ textAlign: 'left', justifyContent: 'left', alignItems: 'left', marginLeft: '40px', marginRight: '40px', marginTop: '30px', "paddingBottom": "200px"}}>
                                <p style={{ fontSize: '16px' }}>Update in progress! We highly regret the inconvience.</p>
                            </div>    
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Body;
