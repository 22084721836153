import React, { useRef, useState } from 'react';
import images from '../../constants/images';
import './Body.css';

import {
    Button,
    TextField,
    InputLabel,
    FormControl,
    NativeSelect,
    Typography,
  } from "@mui/material";
import { Document, Packer, Paragraph, TextRun, AlignmentType } from "docx";
import Box from "@mui/material/Box";
import axios from "axios";
// import Dialog from '@mui/material/Dialog';
// import DialogTitle from '@mui/material/DialogTitle';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


// let API_URL = "http://127.0.0.1:5000/";
// window.API_URL = "http://127.0.0.1:5000/";

// let API_URL = "https://d395b1fe2f7bd4aa89b4bc6eea6f05d75.clg07azjl.paperspacegradient.com/";
// window.API_URL = "https://d395b1fe2f7bd4aa89b4bc6eea6f05d75.clg07azjl.paperspacegradient.com/";

// let API_URL = "https://d611776d2f6b844bba028fe41fcbeb78b.clg07azjl.paperspacegradient.com/";
// window.API_URL = "https://d611776d2f6b844bba028fe41fcbeb78b.clg07azjl.paperspacegradient.com/";

// let API_URL = "http://127.0.0.1:3000/";
// window.API_URL = "http://127.0.0.1:3000/";

// Latest
let API_URL = "https://d155ee79016d840d3b22cab0f4377e7c1.clg07azjl.paperspacegradient.com/";
window.API_URL = "https://d155ee79016d840d3b22cab0f4377e7c1.clg07azjl.paperspacegradient.com/";

var audio_sound;


const Body = () => {

    const [uploadedImage, setUploadedImage] = useState(null);
    // const fileInputRef = useRef(null);
    const [url_link, seturllink] = useState("");
    const [ocrtext, setOcrtext] = useState("");
    const [option_selected, setOptionSelect] = useState("plain");
    const [is_image, setIsImage] = useState(false);
    const [predicted_data, setPredictedData] = useState();
    const [audio_link_v1, setAudioLinkV1] = useState();
    const [audio_link_v2, setAudioLinkV2] = useState();
    const [audio_selector, setAudioSelector] = useState("v1")
    const [show_table, setShowTable] = useState(false);
    const [lines_data, setLinesData] = useState([]);
    const [open, setOpen] = useState(false);
    const [handle_backdrop, setHandleBackDrop] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [image_blob, setImageBlob] = useState();
    const [user_id, setUserId] = useState();
    const [isButtonVisible, setIsButtonVisible] = useState(true);
    const [isImageVisible, setIsImageVisible] = useState(false);
    const [isAudioPlaying, setIsAudioPlaying] = useState(false);
    const [selectedSpeaker, setSelectedSpeaker] = useState("Ali Akbar (Male)");

    var fReader = new FileReader();
    const imageRef=useRef();
    let url_img = "";
    let igo = null;

    // const setZoomTo80 = () => {

    //     const viewportWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    //     console.log(viewportWidth)
    //     const breakpoint = 1536;
    //     if (viewportWidth <= breakpoint) {
    //         const zoomLevel = 80;
    //         document.body.style.zoom = `${zoomLevel}%`;
    //     } else {
    //         document.body.style.zoom = '100%';
    //     }
    //     // document.body.style.zoom = '80%';
    //   };
    // setZoomTo80();
  
    async function gettext(text) {
        let response = await axios.post(window.API_URL + "detection", { file: text });

        if (response.data.limit === "inappropriate") {
            console.error("Inappropriate Input Detected!");
            const alertBox = document.createElement('div');
            alertBox.id = 'alert-box';
            alertBox.innerHTML = `
            <div class="alert-header">
                <span class="alert-icon">⚠️</span>
                <h2>Inappropriate Image Detected!</h2>
            </div>
            <div class="alert-content">
                <p>Please try with a different image, the current image doesn't meet the requirements. Visit the How To page to learn more.</p>
            </div>
            <div class="alert-footer">
                <button class="alert-button" onclick="closeAlertBox()">OK</button>
            </div>
            `;
            document.body.appendChild(alertBox);

            // Style the alert box using CSS
            const style = document.createElement('style');
            style.textContent = `
            #alert-box {
                position: fixed;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 550px;
                background-color: #fff;
                padding: 20px;
                border: 1px solid #ccc;
                border-radius: 5px;
                box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
                z-index: 100;
            }

            .alert-header {
                display: flex;
                align-items: center;
                padding: 10px 0;
                border-bottom: 1px solid #eee;
            }

            .alert-icon {
                font-size: 30px;
                marginRight: 10px;
                color: #f00;
            }

            .alert-content {
                padding: 10px;
            }

            .alert-footer {
                text-align: right;
                padding: 10px 0;
                border-top: 1px solid #eee;
            }

            .alert-button {
                padding: 5px 15px;
                background-color: #007bff;
                color: #fff;
                border: none;
                border-radius: 5px;
                cursor: pointer;
            }
            `;
            document.head.appendChild(style);
            const okButton = document.querySelector('.alert-button');
            okButton.addEventListener('click', closeAlertBox);

            function closeAlertBox() {
            document.body.removeChild(alertBox);
            }
            setHandleBackDrop(false);
            return;
        }

        console.log(response.data.limit);
        // igo = "data:image/jpeg;base64,"+ response.data.predicted_image
        // imageRef.current.src = igo;
        setIsImage(response.data.is_image)
        setPredictedData(response.data.my_data)
        setUserId(response.data.user_id)    
        // setOcrtext(response.data.text);
        setHandleBackDrop(false);

        setHandleBackDrop(true);

        console.log(selectedSpeaker);
        let response1 = await axios.post(window.API_URL + "extraction", {
        my_data: response.data.my_data,
        user_id: response.data.user_id,
        device: 'website',
        selected_speaker: selectedSpeaker,
        });
        // setOcrtext(response1.data.generated_text);
        setOcrtext(response1.data.generated_text.replace(/\n/g, ' '));
        setLinesData(response1.data.text_lines);
        setAudioLinkV1("data:audio/mp3;base64," + response1.data.audio_google);
        setAudioLinkV2("data:audio/wav;base64," + response1.data.audio_facebook);
        setHandleBackDrop(false);

        if (response.data.limit === "warning") {
            
            // setHandleBackDrop(false);
            console.error("Warning! Incorrect Image Dimensions Detected.");
            const alertBox = document.createElement('div');
            alertBox.id = 'alert-box';
            alertBox.innerHTML = `
            <div class="alert-header">
                <span class="alert-icon">⚠️</span>
                <h2>Warning! Image Resized Due To Incorrect Image Dimensions.</h2>
            </div>
            <div class="alert-content">
                <p>The provided image didn't comply with the provided instructions. We have adjusted the image to meet the specified dimensions. Please note that the results may vary. </p>
                <p>For further usage make sure to provide valid image.</p>
            </div>
            <div class="alert-footer">
                <button class="alert-button" onclick="closeAlertBox()">Continue</button>
            </div>
            `;
            document.body.appendChild(alertBox);

            // Style the alert box using CSS
            const style = document.createElement('style');
            style.textContent = `
            #alert-box {
                position: fixed;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 550px;
                background-color: #fff;
                padding: 20px;
                border: 1px solid #ccc;
                border-radius: 5px;
                box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
                z-index: 100;
            }

            .alert-header {
                display: flex;
                align-items: center;
                padding: 10px 0;
                border-bottom: 1px solid #eee;
            }

            .alert-icon {
                font-size: 30px;
                marginRight: 10px;
                color: #f00;
            }

            .alert-content {
                padding: 10px;
            }

            .alert-footer {
                text-align: right;
                padding: 10px 0;
                border-top: 1px solid #eee;
            }

            .alert-button {
                padding: 5px 15px;
                background-color: #007bff;
                color: #fff;
                border: none;
                border-radius: 5px;
                cursor: pointer;
            }
            `;
            

            document.head.appendChild(style);
            const okButton = document.querySelector('.alert-button');
            okButton.addEventListener('click', closeAlertBox);

            function closeAlertBox() {
            document.body.removeChild(alertBox);
            }
        }
    }



    function no_image_error(){
        const alertBox = document.createElement('div');
        alertBox.id = 'alert-box';
        alertBox.innerHTML = `
        <div class="alert-header">
            <span class="alert-icon">⚠️</span>
            <h2>No Image Selected!</h2>
        </div>
        <div class="alert-content">
            <p>Please select an image first before proceeding. Visit the How To page to learn more.</p>
        </div>
        <div class="alert-footer">
            <button class="alert-button" onclick="closeAlertBox()">OK</button>
        </div>
        `;
        document.body.appendChild(alertBox);

        // Style the alert box using CSS
        const style = document.createElement('style');
        style.textContent = `
        #alert-box {
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 550px;
            background-color: #fff;
            padding: 20px;
            border: 1px solid #ccc;
            border-radius: 5px;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
            z-index: 100;
        }

        .alert-header {
            display: flex;
            align-items: center;
            padding: 10px 0;
            border-bottom: 1px solid #eee;
        }

        .alert-icon {
            font-size: 30px;
            marginRight: 10px;
            color: #f00;
        }

        .alert-content {
            padding: 10px;
        }

        .alert-footer {
            text-align: right;
            padding: 10px 0;
            border-top: 1px solid #eee;
        }

        .alert-button {
            padding: 5px 15px;
            background-color: #007bff;
            color: #fff;
            border: none;
            border-radius: 5px;
            cursor: pointer;
        }
        `;
        document.head.appendChild(style);
        const okButton = document.querySelector('.alert-button');
        okButton.addEventListener('click', closeAlertBox);

        function closeAlertBox() {
            document.body.removeChild(alertBox);
        }
        
        setHandleBackDrop(false);
        return;

    }

    function change_to_text(){
        if (isImageVisible) {
            setHandleBackDrop(true);
            fReader.readAsDataURL(image_blob);
            fReader.onloadend = function (event) {
            gettext(event.target.result);
            };  
        } else {
            no_image_error();
        }
    }

    function play_audio() {
        if (audio_selector === "v1") {
          if (audio_link_v1 == null) {
            console.log("no audio found");
          } else {
            if (!isAudioPlaying) {
              // Start playing the audio
              audio_sound = new Audio(audio_link_v1);
              audio_sound.play();
              setIsAudioPlaying(true);
            } else {
              // Stop the audio
              audio_sound.pause();
              audio_sound.currentTime = 0;
              setIsAudioPlaying(false);
            }
          }
        } else {
          if (audio_link_v2 == null) {
            console.log("no audio found");
          } else {
            if (!isAudioPlaying) {
              // Start playing the audio
              audio_sound = new Audio(audio_link_v2);
              audio_sound.play();
              setIsAudioPlaying(true);
            } else {
              // Stop the audio
              audio_sound.pause();
              audio_sound.currentTime = 0;
              setIsAudioPlaying(false);
            }
          }
        }
      }
      
      // Modify the stop_audio function to use the state variable
      function stop_audio() {
        if (audio_sound == null) {
          alert("Please extract the text first");
        } else {
          audio_sound.pause();
          audio_sound.currentTime = 0;
          setIsAudioPlaying(false);
        }
      }


















//   function play_audio(){
   

//     if (audio_selector == "v1" ){
//       if ( audio_link_v1 == null ){
//         console.log("no audio found")
//       }
//       else{
//         audio_sound = null
//         audio_sound = new Audio(audio_link_v1)
//         audio_sound.play()
//       }
//     }
//     else{
//       if ( audio_link_v2 == null ){
//         console.log("no audio found")
//       }
//       else{
//         console.log("hi")
//         console.log(audio_link_v2)
//         audio_sound = null
//         audio_sound = new Audio(audio_link_v2)
//         audio_sound.play()
//       }
//     }
//   }


//   function stop_audio(){
//     if(audio_sound == null){
//       alert("Please extract the text first")
//     }
//     else{
//       audio_sound.pause()
//       audio_sound.currentTime=0
//     }
//   }




    
    function download_file_text(){

        if (isImageVisible) 
        {
            console.log(option_selected)
            if (option_selected === "plain"){
                saveFile();
            }
            else{
                docx_download();
            }
        }
        else{
            no_image_error();
        }
    }

    function saveFile(){

        const type = 'application/text'; // modify or get it from response
        const blob = new Blob([ocrtext], {type});
        const url = URL.createObjectURL(blob);
        // Step 3: Trigger downloading the object using that URL
        const a = document.createElement('a');
        a.href = url;
        a.download = "Urdu Text.txt";
        a.click();
    }

    async function docx_download(){

        const doc = new Document({
            sections: [
                {
                    properties: {},
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.RIGHT,
                            children: [
                                
                                new TextRun({
                                text: ocrtext,
                                font: "Jameel Noori Nastaleeq",  
                                size: 40,
                                }),
                            ],
                        }),
                    ],
                },
            ],
        });

        Packer.toBlob(doc).then((blob) => {
        saveByteArray("Urdu Text.docx", blob);
        });
    }

    async function saveByteArray(reportName, blob) {
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      var fileName = reportName;
      link.download = fileName;
      link.click();
    };      


    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onloadend = () => {
            setUploadedImage(reader.result);
            seturllink(file.name);
            setImageBlob(file);
            setIsImageVisible(true);
            setIsButtonVisible(true);
          };
          reader.readAsDataURL(file);
        }
      };
    
      const triggerFileInput = () => {
        const fileInput = document.createElement("input");
        fileInput.type = "file";
        fileInput.style.display = "none";
        fileInput.accept = "image/*;capture=camera";
        fileInput.addEventListener("change", handleFileChange);
        document.body.appendChild(fileInput);
        fileInput.click();
        document.body.removeChild(fileInput);
      };
    
    const fileInput = React.useRef();
    // const worker = createWorker({});

    let urlChange = (event) => {
    // seturllink(event.target.files[0].value);
    seturllink(fileInput.current.files[0].name);
    setImageBlob(fileInput.current.files[0])
    setIsImageVisible(true);
    setIsButtonVisible(true);
    // setIsButtonVisible(false);

    };

    async function createBlobImage (image_url_link){
    let resp = await fetch( window.location.origin + image_url_link);
    let dat = await resp.blob();
    setImageBlob(dat);
    seturllink(image_url_link);
    setSelectedImage(image_url_link);
    setIsButtonVisible(false);
    setIsImageVisible(true);
    console.log(dat)
    }

    return (
        
    <div className="body-container" style={{display: 'flex', alignItems: 'flex-start', justifyContent: 'center', marginTop: '1%', overflowY: 'auto'}}>
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={handle_backdrop}>
            <CircularProgress color="inherit" />
        </Backdrop>
            <div className="centered-box" style={{ background: '#DEF9FF', boxShadow: "0 0 2% rgba(0, 0, 0, 0.1)", height: '90%', width: '90%', zIndex: '1', borderTopRightRadius: '2%', borderTopLeftRadius: '2%'}}>
                <div className="centered-main-box" style={{ border: '2px dotted #fff', width: '100%', borderTopRightRadius: '1%', borderTopLeftRadius: '1%'}}>
                    <div className='app__body-head' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                        <div className='app__body-head-main' style={{ display: 'flex', flexDirection: 'column', textAlign: 'left'}}>
                            <h2>Image to Text Converter</h2>
                            <p style={{ marginTop: '-20px', marginBottom: '-2px' }}>UrduQaumiZuban makes the process effortless, enabling users to effortlessly extract text from Urdu images.</p>
                        </div>
                        <div className='app__body-head-secondary' style={{ marginTop: '10px' }}>
                            <img src={images.ncl} style={{"marginRight": "10px"}} alt='image' />
                            <img src={images.neduet} style={{"marginRight": "10px"}} alt='image' />
                            <img src={images.atup} alt='image' />
                        </div>
                    </div>

                    <div className='app__body-cards' style={{ width:'98%', height:'100%', justifyContent: 'space-between', display: 'flex', overflow:'auto' }}>
                        <div className='app__body-card' style={{paddingRight:'3%', paddingLeft:'3%', paddingBottom:'0%'}}>                         
                            <div className='app__body-first-card-correct-images' style={{ "paddingTop": '40px' }}>
                                <img src={images.pic1} alt='image' />
                                <img src={images.pic2} alt='image' />
                                <img src={images.pic3} alt='image' />
                                <img src={images.pic4} alt='image' />
                                <img src={images.pic5} alt='image' />
                            </div>
                            <div className='app__body-first-card-text-after-correct-images'>
                                <p style={{ fontSize: '16px' }}>Dont have an image? Try these.</p>
                            </div>
                            <div>
                                <div className='app__body-first-card-sample-images-first'>
                                    <img
                                    src={images.dummy_image1}
                                    alt='image'
                                    onClick={() => createBlobImage(images.dummy_image1)}
                                    className={selectedImage === images.dummy_image1 ? "selected" : ""}
                                    />
                                    <img
                                    src={images.dummy_image2}
                                    alt='image'
                                    onClick={() => createBlobImage(images.dummy_image2)}
                                    className={selectedImage === images.dummy_image2 ? "selected" : ""}
                                    />
                                    <img
                                    src={images.dummy_image3}
                                    alt='image'
                                    onClick={() => createBlobImage(images.dummy_image3)}
                                    className={selectedImage === images.dummy_image3 ? "selected" : ""}
                                    />
                                </div>
                                <div className='app__body-first-card-sample-images-second'>
                                    <img
                                    src={images.dummy_image4}
                                    alt='image'
                                    onClick={() => createBlobImage(images.dummy_image4)}
                                    className={selectedImage === images.dummy_image4 ? "selected" : ""}
                                    />
                                    <img
                                    src={images.dummy_image5}
                                    alt='image'
                                    onClick={() => createBlobImage(images.dummy_image5)}
                                    className={selectedImage === images.dummy_image5 ? "selected" : ""}
                                    />
                                    <img
                                    src={images.dummy_image6}
                                    alt='image'
                                    onClick={() => createBlobImage(images.dummy_image6)}
                                    className={selectedImage === images.dummy_image6 ? "selected" : ""}
                                    />
                                    
                                </div>
                            </div>
                            <div style={{ "paddingTop": '13%'}}>
                            <TextField
                            hiddenLabel
                            size="Normal"
                            variant="standard"
                            placeholder="Selected Filename"
                            value={url_link}
                            margin="dense"
                            style={{ width: '300px' }}
                            />
                            </div>  
                            
                            <div style={{ width: '100%', paddingTop: '2%', paddingBottom: '2%' }}>
                                <Button
                                    variant="contained"
                                    onClick={triggerFileInput}
                                    sx={{
                                    width: '100%', // Button takes up the full width of its container
                                    px: 2,
                                    py: 1,
                                    backgroundColor: "#008080",
                                    borderRadius: 3,
                                    "&:hover": {
                                        backgroundColor: "#08b3e5",
                                    },
                                    display: 'flex', // Add flex display to the button for better content management
                                    justifyContent: 'space-between', // Add space between child elements
                                    }}
                                >
                                    <Typography style={{ flex: 1 , textAlign:'left'}}>
                                    Upload Image
                                    </Typography>
                                    <span style={{ fontFamily: "jameelNoori", fontSize: "18px" }}>
                                    تصویر بھیجیں
                                    </span>
                                </Button>
                            </div>
                        </div>

                        <div className='app__body-card' style={{paddingRight:'3%', paddingLeft:'3%', paddingBottom:'2%'}}> 
                            <div className='app__body-second-card' style={{ "paddingTop": '10px'}}>
                                <p>Image Preview</p>
                                <p style={{direction:"RTL", fontFamily: "jameelNoori"}}>تصویر</p>
                            </div>

                            {/* Custom file upload area */}
                            <div>
                                {isButtonVisible ? (
                                    <label htmlFor="fileInput" style={{ width: '100%', height: '325px', backgroundColor: '#FFFFFF', margin: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', cursor: 'pointer' }}>
                                        {uploadedImage ? (
                                            <img src={uploadedImage} alt="uploaded" style={{ maxWidth: '100%', maxHeight: '100%' }} />
                                        ) : (
                                            <div>
                                                <p>Click to Upload Image</p>
                                                <input
                                                    type="file"
                                                    id="fileInput"
                                                    ref={fileInput}
                                                    style={{ display: 'none' }}
                                                    onChange={handleFileChange}
                                                    accept="image/*;capture=camera"
                                                />
                                            </div>
                                        )}
                                    </label>
                                ) : (
                                    <div style={{ width: '100%', height: '323px', overflow: 'hidden' }}>
                                        <img src={window.location.origin + url_link} alt="uploaded" style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'cover' }} />
                                    </div>



                                )}
                                {/* Additional UI components or logic can be added here */}
                            </div>
     
                             {/*<div style={{ borderBottom: '1px solid black', width: '400px' }}></div>*/}
                            <div style={{ borderBottom: '1px solid white', width: '400px' }}></div>

                            <div>
                                <Box style={{"paddingLeft": '3%',"paddingTop": '1%'}} sx={{ minWidth: 120, maxWidth:200 }}>
                                    <FormControl fullWidth>
                                        <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                        Select Speaker
                                        </InputLabel>
                                        <NativeSelect
                                            value={selectedSpeaker}
                                            onChange={(e) => setSelectedSpeaker(e.target.value)} 
                                        >
                                            <option value="Ali Akbar (Male)">Ali Akbar (Male)</option>
                                            <option value="Robotic (Female)">Robotic (Female)</option>
                                        </NativeSelect>
                                    </FormControl>
                                </Box>
                            </div>

                            <div style={{ width: '100%', paddingTop: '2.5%', paddingBottom: '2%'}}>
                                <Button
                                    variant="contained"
                                    onClick={change_to_text}
                                    sx={{
                                    width: '100%', // Button takes up the full width of its container
                                    px: 2,
                                    py: 1,
                                    backgroundColor: "#008080",
                                    borderRadius: 3,
                                    "&:hover": {
                                        backgroundColor: "#08b3e5",
                                    },
                                    display: 'flex', // Add flex display to the button for better content management
                                    justifyContent: 'space-between', // Add space between child elements
                                    }}
                                >
                                    <Typography style={{ flex: 1 , textAlign:'left'}}>
                                    Get Text
                                    </Typography>
                                    <span style={{ fontFamily: "jameelNoori", fontSize: "18px" }}>
                                    متن نکالیں
                                    </span>
                                </Button>
                            </div>
                        </div>


                        
                        <div className='app__body-card' style={{paddingRight:'3%', paddingLeft: '3%', paddingTop: '10px'}}>

                            <div className='app__body-third-card'>
                                <p>Extracted Text</p>
                                <p style={{direction:"RTL", fontFamily: "jameelNoori"}}>متن</p>
                            </div>
                            <div>
                                <textarea
                                    style={{
                                        width: '100%',
                                        height: '320px',
                                        border: '2px',
                                        resize: 'none',
                                        outline: 'none',
                                        boxShadow: 'none',
                                        direction:"RTL",
                                        fontFamily: "jameelNoori",
                                        fontSize: "20px"
                                    }}
                                    readOnly
                                    value={ocrtext}
                                    placeholder="Text from image will appear here..."
                                    wrap="True"
                                ></textarea>
                            </div>

                            <div>
                                <Box style={{"paddingLeft": '3%',"paddingTop": '1%'}} sx={{ minWidth: 120, maxWidth:200 }}>
                                    <FormControl fullWidth>
                                        <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                        Select Format
                                        </InputLabel>
                                        <NativeSelect
                                        onChange={ (e)=>{setOptionSelect(e.target.value)}  }
                                        defaultValue="plain"
                                        inputProps={{
                                            name: "outputformat",
                                            id: "uncontrolled-native",
                                        }}
                                        >
                                        <option value="plain">Text Plain (txt)</option>
                                        <option value="docx">Microsoft word (docx)</option>
                                        {/* <option value={30}>Thirty</option> */}
                                        </NativeSelect>
                                    </FormControl>
                                </Box>
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '10px', gap: '5%' }}>
                                <Button
                                    onClick={download_file_text}
                                    variant="contained"
                                    sx={{
                                        px: 2,
                                        py: 1,
                                        backgroundColor: "#008080",
                                        borderRadius: 3,
                                        "&:hover": {
                                            backgroundColor: "#08b3e5",
                                        },
                                        display: 'flex', // Flex display for button content
                                        justifyContent: 'space-between', // Space between the two languages
                                        width: '200px', // Setting a consistent button width
                                    }}
                                >
                                    <Typography style={{ flex: 1 , textAlign:'left'}}>
                                        Download
                                    </Typography>
                                    <Typography style={{ fontFamily: "jameelNoori", fontSize: "18px", textAlign: 'right' }}>
                                        ڈاؤن لوڈ
                                    </Typography>
                                </Button>

                                <Button
                                    onClick={play_audio}
                                    variant="contained"
                                    sx={{
                                        px: 2,
                                        py: 1,
                                        backgroundColor: "#008080",
                                        borderRadius: 3,
                                        "&:hover": {
                                            backgroundColor: "#08b3e5",
                                        },
                                        display: 'flex', // Flex display for button content
                                        justifyContent: 'space-between', // Space between the two languages
                                        width: '200px', // Setting a consistent button width
                                    }}
                                >
                                    <Typography style={{ flex: 1 , textAlign:'left'}}>
                                        {isAudioPlaying ? 'Stop Audio' : 'Play Audio'}
                                    </Typography>
                                    <Typography style={{ fontFamily: "jameelNoori", fontSize: "18px", textAlign: 'right' }}>
                                        آڈیو {isAudioPlaying ? 'رکیں' : 'چلائیں'}
                                    </Typography>
                                </Button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
    
};

export default Body;
