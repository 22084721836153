import React, { useRef, useState } from 'react';
import images from '../../constants/images';
import './Body.css';

const Body = () => {

    // const setZoomTo80 = () => {

    //     const viewportWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    //     console.log(viewportWidth)
    //     const breakpoint = 1536;
    //     if (viewportWidth <= breakpoint) {
    //         const zoomLevel = 80;
    //         document.body.style.zoom = `${zoomLevel}%`;
    //     } else {
    //         document.body.style.zoom = '100%';
    //     }
    //     // document.body.style.zoom = '80%';
    //   };
    // setZoomTo80();
    
    return (
        
    <div className="body-container">
            <div className="centered-box">
                <div className="centered-main-box">
                    <div className='app__body-head'>
                        <div className='app__body-head-main'>
                            <h2>About Urdu Qaumi Zuban</h2>
                            <p style={{ marginTop: '-20px', marginBottom: '-2px'}}>Bringing Urdu into the digital age.</p>
                        </div>
                        <div className='app__body-head-secondary' style={{ marginTop: '10px' }}>
                            <img src={images.ncl} style={{"marginRight": "10px"}} alt='image' />
                            <img src={images.neduet} style={{"marginRight": "10px"}} alt='image' />
                            <img src={images.atup} alt='image' />
                        </div>
                    </div>

                    <div className='app__body-cards' style={{marginLeft: '10px'}}>
                        <div className='app__body-card'>                     
  
                            <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'left', alignItems: 'left', marginBottom: '-35px', marginLeft: '40px'}}>
                                <p style={{ fontSize: '28px', fontStyle:"bold"}}>UrduQaumiZuban.com</p>
                            </div>
                            <div style={{ textAlign: 'left', justifyContent: 'left', alignItems: 'left', marginLeft: '40px', marginRight: '40px'}}>
                                <p style={{ fontSize: '16px' }}>UrduQaumiZuban.com offers a seamless solution to convert printed Urdu Nastaleeq documents into machine readable(editable) text and high-quality audio with high accuracy in just seconds.</p>
                            </div>

                            <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'left', alignItems: 'left', marginBottom: '-35px', marginLeft: '40px'}}>
                                <p style={{ fontSize: '28px', fontStyle:"bold"}}>Key Features</p>
                            </div>
                            <div style={{ textAlign: 'left', justifyContent: 'left', alignItems: 'left', marginLeft: '40px', marginRight: '40px'}}>
                                <p style={{ fontSize: '16px' }}> <strong> 1.	User-Friendly Web & Android Applications:</strong> Enjoy the convenience of a straightforward and easy-to-use interface on both web and Android platforms.</p>
                            </div>

                            <div style={{ textAlign: 'left', justifyContent: 'left', alignItems: 'left', marginLeft: '40px', marginRight: '40px'}}> 
                                <p style={{ fontSize: '16px' }}> <strong> 2.	Robust Line Extraction:</strong> Ensuring precision and reliability in extracting text lines.</p>
                            </div>

                            <div style={{ textAlign: 'left', justifyContent: 'left', alignItems: 'left', marginLeft: '40px', marginRight: '40px'}}>
                                <p style={{ fontSize: '16px' }}> <strong> 3.	Efficient Preprocessing:</strong> Optimal handling of camera-captured mobile images, including de-skewing and light correction, for enhanced readability.</p>
                            </div>

                            <div style={{ textAlign: 'left', justifyContent: 'left', alignItems: 'left', marginLeft: '40px', marginRight: '40px'}}>
                                <p style={{ fontSize: '16px' }}> <strong> 4.	Noori Nastaleeq Font Support: </strong> Convert images into text while accommodating font sizes in range 20-30.</p>
                            </div>

                            <div style={{ textAlign: 'left', justifyContent: 'left', alignItems: 'left', marginLeft: '40px', marginRight: '40px'}}>
                                <p style={{ fontSize: '16px' }}> <strong> 5.	Text-to-Speech Conversion:</strong> Delivering an auditory experience through text-to-speech functionality.</p>
                            </div>


                            <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'left', alignItems: 'left', marginBottom: '-35px', marginLeft: '40px'}}>
                                <p style={{ fontSize: '28px', fontStyle:"bold"}}>Supported Input Formats: </p>
                            </div>
                            <div style={{ textAlign: 'left', justifyContent: 'left', alignItems: 'left', marginLeft: '40px', marginRight: '40px'}}>
                                <p style={{ fontSize: '16px' }}> <strong> 1.	PNG</strong> (Portable Network Graphics) </p>
                            </div>

                            <div style={{ textAlign: 'left', justifyContent: 'left', alignItems: 'left', marginLeft: '40px', marginRight: '40px'}}>
                                <p style={{ fontSize: '16px' }}> <strong> 2.    JPG</strong> (Joint Photographic Group) </p>
                            </div>

                            <div style={{ textAlign: 'left', justifyContent: 'left', alignItems: 'left', marginLeft: '40px', marginRight: '40px'}}>
                                <p style={{ fontSize: '16px' }}> <strong> 3.	JPEG</strong> (Joint Photographic Experts Group)</p>
                            </div>



                            <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'left', alignItems: 'left', marginBottom: '-35px', marginLeft: '40px'}}>
                                <p style={{ fontSize: '28px', fontStyle:"bold"}}>Output Formats:</p>
                            </div>
                            <div style={{ textAlign: 'left', justifyContent: 'left', alignItems: 'left', marginLeft: '40px', marginRight: '40px'}}>
                                <p style={{ fontSize: '16px' }}> <strong> 1.	Editable Urdu Text Files:</strong> Choose between txt and Word formats for easy editing and sharing. </p>
                            </div>

                            <div style={{ textAlign: 'left', justifyContent: 'left', alignItems: 'left', marginLeft: '40px', marginRight: '40px'}}>
                                <p style={{ fontSize: '16px' }}> <strong> 2.	Audio Playback of Text Output:</strong> Seamlessly listen to the converted text for added accessibility and convenience. </p>
                            </div>



                            <div style={{ textAlign: 'left', justifyContent: 'left', alignItems: 'left', marginLeft: '40px', marginRight: '40px', marginTop: '30px', marginBottom: '40px'}}>
                                <p style={{ fontSize: '16px' }}>Discover the convenience and efficiency of UrduQaumiZuban.com, where printed Urdu documents effortlessly transform into machine-readable text and high-quality audio in a matter of seconds. Experience a new realm of accessibility and ease in utilizing the Urdu language, all at your fingertips.</p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Body;
