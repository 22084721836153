import bg_image from '../assets/bg_image.png'
import logo from '../assets/logo.png'
import atup from '../assets/atup_logo.png'
import facebook from '../assets/facebook_logo.png'
import instagram from '../assets/instagram_logo.png'
import gmail from '../assets/gmail_logo.png'
import ncl from '../assets/ncl_logo.png'
import neduet from '../assets/neduet_logo.png'
import bg_image_right_top from '../assets/bg_image_right_top.png'
import bg_image_left_bottom from '../assets/bg_image_left_bottom.png'
import pic1 from '../assets/pic1.png'
import pic2 from '../assets/pic2.png'
import pic3 from '../assets/pic3.png'
import pic4 from '../assets/pic4.png'
import pic5 from '../assets/pic5.png'
import sample1 from '../assets/sample1.png'
import sample2 from '../assets/sample2.png'
import sample3 from '../assets/sample3.png'
import sample4 from '../assets/sample4.png'
import dummy_image1 from "../assets/dummy1.jpg";
import dummy_image2 from "../assets/dummy2.jpg";
import dummy_image3 from "../assets/dummy3.jpg";
import dummy_image4 from "../assets/dummy4.jpg";
import dummy_image5 from "../assets/dummy5.jpg";
import dummy_image6 from "../assets/dummy6.jpg";
import enlarge_icon from "../assets/enlarge.png";
import incorrect_samples from "../assets/incorrect_samples_large.png";
import correct_samples from "../assets/correct_samples.png";
import lodhi from "../assets/lodhi.png";
import saad from "../assets/saad.png";
import majida from "../assets/majida.png";
import fozia from "../assets/fozia_yasir.png";
import zahida from "../assets/zahida_hina.png";
import hamza from "../assets/hamza.jpg";
import bisma from "../assets/bisma.png";
import saqib from "../assets/saqib.png";
import wajid from "../assets/wajid.png";
import danish from "../assets/danish.png";
import samreen from "../assets/samreen.png";
import abid from "../assets/abid.png";
import muhammad from "../assets/muhammad.png";
import qrcode from "../assets/qrcode.png";
import mobile_process from "../assets/mobile_process.png";
import website_navigation from "../assets/website_navigation.png";
import mobile_navigation from "../assets/mobile_navigation.png";
import urduhaimeraname from "../assets/urduhaimeraname.png";
import application_logo_new from "../assets/application_logo_new.png";
import application_logo_new_small from "../assets/application_logo_new_small.png";


export default {
    bg_image,
    logo,
    bg_image_right_top,
    bg_image_left_bottom,
    atup,
    facebook,
    instagram,
    gmail,
    ncl,
    neduet,
    pic1,
    pic2,
    pic3,
    pic4,
    pic5,
    sample1,
    sample2,
    sample3,
    sample4,
    dummy_image1,
    dummy_image2,
    dummy_image3,
    dummy_image4,
    dummy_image5,
    dummy_image6,
    enlarge_icon,
    incorrect_samples,
    saad,
    majida,
    fozia,
    hamza,
    saqib,
    wajid,
    abid,
    muhammad,
    qrcode,
    mobile_process,
    bisma,
    lodhi,
    danish,
    samreen,
    website_navigation,
    mobile_navigation,
    correct_samples,
    urduhaimeraname,
    zahida,
    application_logo_new,
    application_logo_new_small
};