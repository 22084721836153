import React, { useRef, useState } from 'react';
import images from '../../constants/images';
import './Body.css';

import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import ImageTextCard from "../../constants/ImageTextCard";
// import Typography from "@mui/material/Typography";
// import Divider from "@mui/material/Divider";

const drawerWidth = 240;

const Body = () => {
    // const setZoomTo80 = () => {

    //     const viewportWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    //     console.log(viewportWidth)
    //     const breakpoint = 1536;
    //     if (viewportWidth <= breakpoint) {
    //         const zoomLevel = 80;
    //         document.body.style.zoom = `${zoomLevel}%`;
    //     } else {
    //         document.body.style.zoom = '100%';
    //     }
    //     // document.body.style.zoom = '80%';
    //   };
    // setZoomTo80();
    return (
        
    <div className="body-container">
            <div className="centered-box">
                <div className="centered-main-box">
                    <div className='app__body-head'>
                        <div className='app__body-head-main'>
                            <h2>Meet the Team</h2>
                            <p style={{ marginTop: '-20px', marginBottom: '-2px'}}> Get to know the faces behind the innovation.</p>
                        </div>
                        <div className='app__body-head-secondary' style={{ marginTop: '10px' }}>
                            <img src={images.ncl} style={{"marginRight": "10px"}} alt='image' />
                            <img src={images.neduet} style={{"marginRight": "10px"}} alt='image' />
                            <img src={images.atup} alt='image' />
                        </div>
                    </div>

                    <div className='app__body-cards' style={{marginLeft: '10px'}}>
                        <div className='app__body-card'>                     
  
                        <Box component="main"
                            sx={{
                                flexGrow: 1,
                                p: 2,
                                justifyContent: "center",
                                width: { sm: `calc(100% - ${drawerWidth}px)` },
                            }} style={{marginLeft: '100px', paddingTop:'-10px', paddingBottom:'100px'}}
                            >
                            <Toolbar />

                            {/* Meet the team */}
                            {/* team members */}
                            {/* Dr.majida and Dr.saad */}

                            <Grid
                            container
                            rowSpacing={1} 
                            sx={{ justifyContent: "center", margin: "1px"}}
                            >

                            <Grid item container display="flex" justifyContent="center" xs={1} sm={1} md={2}>
                                <ImageTextCard
                                img={images.lodhi}
                                text="Dr. Sarosh Hashmat Lodi"
                                title="Vice-Chancellor,
                                NED University of Engineering & Technology"
                                style={{ margin: '1px' }}
                                />
                            </Grid>

                            <Grid item container display="flex" justifyContent="center" xs={1} sm={1} md={2}>
                                <ImageTextCard
                                img={images.saad}
                                text="Dr. Saad Ahmed Qazi"
                                title="Principal Investigator, Neurocomputation Lab, NCAI"
                                style={{ margin: '1px' }}
                                />
                            </Grid>

                            <Grid item container display="flex" justifyContent="center" xs={1} sm={1} md={2}>
                                <ImageTextCard
                                    img={images.wajid}
                                    text="Wajid Jawad"
                                    title="President, Anjuman Taraqqi-e-Urdu Pakistan"
                                />
                                </Grid>

                                <Grid item container display="flex" justifyContent="center" xs={1} sm={1} md={2}>
                                <ImageTextCard
                                    img={images.abid}
                                    text="Syed Abid Rizvi"
                                    title="Treasurer, Anjuman Taraqqi-e-Urdu Pakistan"
                                />
                                </Grid>

                                <Grid item container display="flex" justifyContent="center" xs={1} sm={1} md={2}>
                                <ImageTextCard
                                    img={images.zahida}
                                    text="Zaheda Hina"
                                    title="General Secretary, Anjuman Taraqqi-e-Urdu Pakistan"
                                />
                                </Grid>
                            </Grid>




                            <Grid
                                container
                                // columnSpacing={{ xs: 4, sm: 5, md: 6 }}
                                // rowSpacing={{ xs: 5, sm: 5 }}
                                // sx={{ justifyContent: "center", pt: 5 }}
                                rowSpacing={1,5} 
                                sx={{ justifyContent: "center", margin: "1px"}}
                            >

                               <Grid item container display="flex" justifyContent="center" xs={1} sm={1} md={2}>
                                <ImageTextCard
                                img={images.majida}
                                text="Dr. Majida Kazmi"
                                title="Project Lead & Co-PI, Neurocomputation Lab, NCAI"
                                />
                                </Grid>

                                <Grid item container display="flex" justifyContent="center" xs={1} sm={1} md={2}>
                                <ImageTextCard
                                    img={images.saqib}
                                    text="Dr.-Ing Syed Saqib Bukhari"
                                    title="Domain Expert"
                                />
                                </Grid>

                                <Grid item container display="flex" justifyContent="center" xs={1} sm={1} md={2}>
                                <ImageTextCard
                                    img={images.fozia}
                                    text="Ms. Fauzia Yasir"
                                    title="Research Fellow"
                                />
                            </Grid>
                            
                            <Grid item container display="flex" justifyContent="center" xs={1} sm={1} md={2}>
                                <ImageTextCard
                                    img={images.hamza}
                                    text="Hamza Munir Kidwai"
                                    title="Research Associate"
                                />
                            </Grid>
                            <Grid item container display="flex" justifyContent="center" xs={1} sm={1} md={2}>
                                <ImageTextCard
                                    img={images.bisma}
                                    text="Bisma Imran"
                                    title="Research Assistant"
                                />
                            </Grid>                           


                            </Grid>


                            {/* three members here RAs*/}

                            <Grid
                                container
                                // columnSpacing={{ xs: 4, sm: 5, md: 6 }}
                                // rowSpacing={{ xs: 5, sm: 5 }}
                                // sx={{ justifyContent: "center", pt: 5 }}
                                rowSpacing={1,5} 
                                sx={{ justifyContent: "center", margin: "1px"}}
                            >

                            <Grid item container display="flex" justifyContent="center" xs={1} sm={1} md={2}>
                                <ImageTextCard
                                    img={images.muhammad}
                                    text="Syed Muhammad"
                                    title="Apprenticeship"
                                />
                            </Grid>

                            <Grid item container display="flex" justifyContent="center" xs={1} sm={1} md={2}>
                                <ImageTextCard
                                    img={images.samreen}
                                    text="Samreen Habib"
                                    title="Research Assistant"
                                />
                            </Grid>


                            <Grid item container display="flex" justifyContent="center" xs={1} sm={1} md={2}>
                                <ImageTextCard
                                    img={images.danish}
                                    text="Danish Inam"
                                    title="Research Assistant"
                                />
                            </Grid>
                            
                            
                            </Grid>
                            </Box>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Body;
