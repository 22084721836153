import React, { useRef, useState } from 'react';
import images from '../../constants/images';
import './Body.css';

const Body = () => {
    // const setZoomTo80 = () => {

    //     const viewportWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    //     console.log(viewportWidth)
    //     const breakpoint = 1536;
    //     if (viewportWidth <= breakpoint) {
    //         const zoomLevel = 80;
    //         document.body.style.zoom = `${zoomLevel}%`;
    //     } else {
    //         document.body.style.zoom = '100%';
    //     }
    //     // document.body.style.zoom = '80%';
    //   };
    // setZoomTo80();
    return (
        
    <div className="body-container">
            <div className="centered-box">
                <div className="centered-main-box">
                    <div className='app__body-head'>
                        <div className='app__body-head-main'>
                            <h2>Instructions</h2>
                            <p style={{ marginTop: '-20px', marginBottom: '-2px'}}>Finding it difficult to use the app? Follow these guidelines to ensure the best possible experience when converting images into text.</p>
                        </div>
                        <div className='app__body-head-secondary' style={{ marginTop: '10px' }}>
                            <img src={images.ncl} style={{"marginRight": "10px"}} alt='image' />
                            <img src={images.neduet} style={{"marginRight": "10px"}} alt='image' />
                            <img src={images.atup} alt='image' />
                        </div>
                    </div>

                    <div className='app__body-cards' style={{marginLeft: '10px'}}>
                        <div className='app__body-card'>  
                            <div style={{ textAlign: 'left', justifyContent: 'left', alignItems: 'left', marginLeft: '40px', marginRight: '40px', marginTop: '30px', "paddingBottom": "200px"}}>
                                <p style={{ fontSize: '16px' }}>Update in progress! We highly regret the inconvience.</p>
                            </div>      


 {/* 
                            <div style={{ textAlign: 'left', justifyContent: 'left', alignItems: 'left', marginLeft: '40px', marginRight: '40px', marginTop: '30px'}}>
                                <p style={{ fontSize: '16px' }}>Welcome to the Dastaan.io Help Section! Here, you'll find detailed information to assist you in navigating and making the most of our powerful Urdu OCR with Audio Conversion application. </p>
                            </div>                    */}

                        {/* Getting Started */}
                        {/* <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'left', alignItems: 'left', marginBottom: '-35px', marginLeft: '40px'}}>
                                <p style={{ fontSize: '28px', fontStyle:"bold"}}>Getting Started</p>
                            </div>
                            <div style={{ textAlign: 'left', justifyContent: 'left', alignItems: 'left', marginLeft: '40px', marginRight: '40px'}}>
                                <p style={{ fontSize: '16px' }}>•	Install Dastaan.io on your Android device or access it through the web.</p>
                            </div>
                            <div style={{ textAlign: 'left', justifyContent: 'left', alignItems: 'left', marginLeft: '40px', marginRight: '40px', marginTop: '-10px'}}>
                                <p style={{ fontSize: '16px' }}>•	Complete user registration for a personalized experience. (To be added)</p>
                            </div> */}


                        {/* User Interface Navigation */}
                        {/* <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'left', alignItems: 'left', marginBottom: '-35px', marginLeft: '40px'}}>
                                <p style={{ fontSize: '28px', fontStyle:"bold"}}>User Interface Navigation</p>
                            </div>
                            <div style={{ textAlign: 'left', justifyContent: 'left', alignItems: 'left', marginLeft: '40px', marginRight: '40px'}}>
                                <p style={{ fontSize: '16px' }}>•	Explore the user-friendly interface on both web and Android platforms.</p>
                            </div>
                            <div style={{ textAlign: 'left', justifyContent: 'left', alignItems: 'left', marginLeft: '40px', marginRight: '40px', marginTop: '-10px',}}>
                                <p style={{ fontSize: '16px' }}>•	Navigate through the application effortlessly with our intuitive design.</p>
                            </div> */}


                        {/* Picture Capturing Tips */}
                        {/* <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'left', alignItems: 'left', marginBottom: '-35px', marginLeft: '40px'}}>
                                <p style={{ fontSize: '28px', fontStyle:"bold"}}>Picture Capturing Tips</p>
                            </div>
                            <div style={{ textAlign: 'left', justifyContent: 'left', alignItems: 'left', marginLeft: '40px', marginRight: '40px'}}>
                                <p style={{ fontSize: '16px' }}>•	Dastaan.io works best on images with a width of 1241 pixels(A4 size document). For the most accurate and efficient text extraction, ensure your document images adhere to this recommended size.</p>
                            </div>
                            <div style={{ textAlign: 'left', justifyContent: 'left', alignItems: 'left', marginLeft: '40px', marginRight: '40px', marginTop: '-10px',}}>
                                <p style={{ fontSize: '16px' }}>•	Ensure that the printed Nastaleeq font falls within the size range of 20-30.</p>
                            </div>
                            <div style={{ textAlign: 'left', justifyContent: 'left', alignItems: 'left', marginLeft: '40px', marginRight: '40px', marginTop: '-10px',}}>
                                <p style={{ fontSize: '16px' }}>•	Avoid selecting images with very small or large font sizes, excessively wide dimensions, improper image dimensions, double columns, or images that incorporate pictures, as these factors may diminish the application's precision. </p>
                            </div>
                            <div style={{ textAlign: 'left', justifyContent: 'left', alignItems: 'left', marginLeft: '40px', marginRight: '40px', marginTop: '-10px',}}>
                                <p style={{ fontSize: '16px' }}>•	Stick to the ideal conditions to enhance the accuracy of the conversion process.</p>
                            </div> */}


                        {/* Text-to-Speech Conversion */}
                        {/* <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'left', alignItems: 'left', marginBottom: '-35px', marginLeft: '40px'}}>
                                <p style={{ fontSize: '28px', fontStyle:"bold"}}>Text-to-Speech Conversion</p>
                            </div>
                            <div style={{ textAlign: 'left', justifyContent: 'left', alignItems: 'left', marginLeft: '40px', marginRight: '40px'}}>
                                <p style={{ fontSize: '16px' }}>•	Experience accurate text-to-speech conversion for an enhanced auditory experience.</p>
                        </div> */}



                        {/* Output Formats */}
                        {/* <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'left', alignItems: 'left', marginBottom: '-35px', marginLeft: '40px'}}>
                                <p style={{ fontSize: '28px', fontStyle:"bold"}}>Output Formats</p>
                            </div>
                            <div style={{ textAlign: 'left', justifyContent: 'left', alignItems: 'left', marginLeft: '40px', marginRight: '40px'}}>
                                <p style={{ fontSize: '16px' }}>•	Select between editable Urdu text files in TXT or Word formats for easy editing and sharing.</p>
                            </div> */}

                        
                        {/* Output Formats */}
                        {/* <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'left', alignItems: 'left', marginBottom: '-35px', marginLeft: '40px'}}>
                                <p style={{ fontSize: '28px', fontStyle:"bold"}}>Updates and Improvements</p>
                            </div>
                            <div style={{ textAlign: 'left', justifyContent: 'left', alignItems: 'left', marginLeft: '40px', marginRight: '40px'}}>
                                <p style={{ fontSize: '16px' }}>•	Stay informed about the latest features, updates, and improvements to enhance your Dastaan.io experience.</p>
                            </div> */}


                        {/* Troubleshooting and Support */}
                        {/* <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'left', alignItems: 'left', marginBottom: '-35px', marginLeft: '40px'}}>
                                <p style={{ fontSize: '28px', fontStyle:"bold"}}>Troubleshooting and Support</p>
                            </div>
                            <div style={{ textAlign: 'left', justifyContent: 'left', alignItems: 'left', marginLeft: '40px', marginRight: '40px'}}>
                                <p style={{ fontSize: '16px' }}>•	Contact our support team for assistance with any challenges you may encounter.</p> */}
                            {/* </div> */}

                    
                        {/* Installation Guide */}
                        {/* <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'left', alignItems: 'left', marginBottom: '-35px', marginLeft: '40px'}}>
                                <p style={{ fontSize: '28px', fontStyle:"bold"}}>Installation Guide</p>
                            </div>
                            <div style={{ textAlign: 'left', justifyContent: 'left', alignItems: 'left', marginLeft: '40px', marginRight: '40px'}}>
                                <p style={{ fontSize: '16px' }}>•	Scan the QR code below to download android applicaiton on your mobile.</p>
                                
                                <div style={{"paddingTop": "30px", "paddingBottom": "10px"}}>
                                <img
                                    src={images.qrcode}
                                    alt='image'
                                    />
                                </div>    

                                <p style={{ fontSize: '16px' }}>•	Follow the visual guide.</p>
                        </div> */}


                        {/* Interface Navigation */}
                        {/* <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'left', alignItems: 'left', marginBottom: '-35px', marginLeft: '40px'}}>
                                <p style={{ fontSize: '28px', fontStyle:"bold"}}>Website Interface Navigation</p>
                            </div>
                            <div style={{ textAlign: 'left', justifyContent: 'left', alignItems: 'left', marginLeft: '40px', marginRight: '40px'}}>
                                <p style={{ fontSize: '16px' }}></p>
                        </div>

                        <div style={{"paddingTop": "30px", "paddingBottom": "10px"}}>
                            <img
                                src={images.website_navigation}
                                alt='image'
                                />
                        </div>     */}

                        {/* Interface Navigation */}
                        {/* <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'left', alignItems: 'left', marginBottom: '-35px', marginLeft: '40px'}}>
                                <p style={{ fontSize: '28px', fontStyle:"bold"}}>Mobile Application Navigation</p>
                        </div> */}


{/* 
                        <div style={{"paddingTop": "30px", "paddingBottom": "10px"}}>
                            <img
                                src={images.mobile_navigation}
                                alt='image'
                                />
                        </div>     */}

                        {/* Correct Samples */}
                        {/* <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'left', alignItems: 'left', marginBottom: '-35px', marginLeft: '40px'}}>
                                <p style={{ fontSize: '28px', fontStyle:"bold"}}>Correct Samples</p>
                        </div>

                        <div style={{"paddingTop": "30px", "paddingBottom": "10px"}}>
                            <img
                                src={images.correct_samples}
                                alt='image'
                                /> */}
                        {/* </div>    */}

                        {/* Incorrect Samples */}
                        {/* <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'left', alignItems: 'left', marginBottom: '-35px', marginLeft: '40px'}}>
                                <p style={{ fontSize: '28px', fontStyle:"bold"}}>Incorrect Samples</p>
                        </div>

                        <div style={{"paddingTop": "30px", "paddingBottom": "10px"}}>
                            <img
                                src={images.incorrect_samples}
                                alt='image'
                                />
                        </div>     

                        <div style={{ textAlign: 'left', justifyContent: 'left', alignItems: 'left', marginLeft: '40px', marginRight: '40px'}}>
                            <p style={{ fontSize: '16px' }}>Remember, Dastaan.io is designed to make your Urdu OCR experience seamless and efficient. If you have further questions or need additional assistance, don't hesitate to reach out to our support team.</p>
                        </div> */}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Body;
