import React, { useRef, useState } from 'react';
import images from '../../constants/images';
import './Body.css';

const Body = () => {
    // const setZoomTo80 = () => {

    //     const viewportWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    //     console.log(viewportWidth)
    //     const breakpoint = 1536;
    //     if (viewportWidth <= breakpoint) {
    //         const zoomLevel = 80;
    //         document.body.style.zoom = `${zoomLevel}%`;
    //     } else {
    //         document.body.style.zoom = '100%';
    //     }
    //     // document.body.style.zoom = '80%';
    //   };
    // setZoomTo80();
    return (
        
    <div className="body-container">
            <div className="centered-box">
                <div className="centered-main-box">
                    <div className='app__body-head'>
                        <div className='app__body-head-main'>
                            <h2>Our Contributions</h2>
                            <p style={{ marginTop: '-20px', marginBottom: '-2px'}}>See what we have been upto all this time.</p>
                        </div>
                        <div className='app__body-head-secondary' style={{ marginTop: '10px' }}>
                            <img src={images.ncl} style={{"marginRight": "10px"}} alt='image' />
                            <img src={images.neduet} style={{"marginRight": "10px"}} alt='image' />
                            <img src={images.atup} alt='image' />
                        </div>
                    </div>

                    <div className='app__body-cards' style={{marginLeft: '10px'}}>
                        <div className='app__body-card'>       

                            <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'left', alignItems: 'left', marginBottom: '-35px', marginLeft: '40px'}}>
                                <p style={{ fontSize: '28px', fontStyle:"bold"}}>Urdu Image Corpora</p>
                            </div>

                            <div style={{ textAlign: 'left', justifyContent: 'left', alignItems: 'left', marginLeft: '40px', marginRight: '40px'}}>
                                <p style={{ fontSize: '16px' }}>We are creating an image corpus of Urdu ligatures, words, and sentences from online sources typed in multi-style.</p>
                            </div>    

                            <div style={{ textAlign: 'left', justifyContent: 'left', alignItems: 'left', marginLeft: '40px', marginRight: '40px'}}>
                                <p style={{ fontSize: '16px' }}> <strong> 1.	Data Sources:</strong> BBC Urdu, Daily Pakistan, Urdu Point, ATUP, UPTI.  </p>
                            </div>

                            <div style={{ textAlign: 'left', justifyContent: 'left', alignItems: 'left', marginLeft: '40px', marginRight: '40px'}}>
                                <p style={{ fontSize: '16px' }}> <strong> 2.	Text Styles:</strong> Jameel Noori Nastaleeq Regular </p>
                            </div>

                              <div style={{ textAlign: 'left', justifyContent: 'left', alignItems: 'left', marginLeft: '40px', marginRight: '40px'}}>
                                <p style={{ fontSize: '16px' }}> <strong> 2.	Data Size:</strong> Dataset consisting of over 3 Million sentences </p>
                            </div>            
  
                            <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'left', alignItems: 'left', marginBottom: '-35px', marginLeft: '40px', paddingTop: '17px', paddingBottom:'10px'}}>
                                <p style={{ fontSize: '28px', fontStyle:"bold"}}>Publications</p>
                            </div>
                            <br></br>
                            <div style={{ textAlign: 'left', justifyContent: 'left', alignItems: 'left', marginLeft: '40px', marginRight: '40px', paddingTop:'10px', borderRadius: '10px', backgroundColor:'#DEF9FF', padding:'1%'}}>
                                <a href="https://scholar.google.com.pk/citations?view_op=view_citation&hl=en&user=VbBLVtgAAAAJ&sortby=pubdate&citation_for_view=VbBLVtgAAAAJ:LkGwnXOMwfcC" style={{ fontSize: '16px' }}><strong>1. </strong>      M. Kazmi, F. Yasir, S. Habib, M. S. Hayat, and S. A. Qazi, <strong>“Photometric Ligature Extraction Technique for Urdu Optical Character Recognition”</strong>, Eng. Technol. Appl. Sci. Res., vol. 11, no. 6, pp. 7968–7973, Dec. 2021.</a>
                            </div>
                            <br></br>
                            <div style={{ textAlign: 'left', justifyContent: 'left', alignItems: 'left', marginLeft: '40px', marginRight: '40px', paddingBottom: '60px', paddingTop:'10px' ,borderRadius: '10px', backgroundColor:'#DEF9FF', padding:'1%'}}>
                                <a href="https://scholar.google.com.pk/citations?view_op=view_citation&hl=en&user=VbBLVtgAAAAJ&sortby=pubdate&citation_for_view=VbBLVtgAAAAJ:YsMSGLbcyi4C" style={{ fontSize: '16px' }}><strong>2. </strong>     H. R. Khan, M. A. Hasan, M. Kazmi, N. Fayyaz, H. Khalid, and S. A. Qazi, <strong>“A Holistic Approach to Urdu Language Word Recognition using Deep Neural Networks”</strong>, Eng. Technol. Appl. Sci. Res., vol. 11, no. 3, pp. 7140–7145, Jun. 2021.</a>
                            </div>
                            <br></br>




                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Body;
